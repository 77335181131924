.patient_care_kpj_hero_section {
  background-repeat: no-repeat;
  min-height: 300px;
  background-position: center top;
  background-size: cover;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: inherit;

    h1 {
      color: black;
    }

    #back_to_btn {
      border: 0;
      background-color: transparent;
      color: #967F65;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.sidebar_group {
  display: flex;
  .sidebar {
    flex: 1;
    width: 25%;
    background-color: rgb(243, 245, 247);

    .container {
      padding: 50px 0;

      ul {
        width: 100%;
        li {
          font-size: 18px;
          color: black;
          cursor: pointer;

          &.active {
            font-size: 22px;
            font-weight: 700;
            color: #967F65;
            position: relative;
            padding-left: 30px;

            span {
              position: absolute;
              left: 0;
              font-weight: 900;
            }
          }

          .dropdown_admission {
            display: block;

            li {
              color: black;
              font-size: 14px;
              cursor: pointer;
              font-weight: 400;
              padding-left: 0;
              line-height: 1.9;

              &.active {
                color: #967F65;
              }
            }
          }
        }
      }
    }
  }

  .sidebar_content {
    flex: 2;
    width: 75%;
    float: right;
    // height: 100%;
    background-color: #fff;

    .container {
      padding: 50px;
    }
  }
}

.outpatient_clinic_section {
  #long_text {
    font-size: 16px;
    color: black;
    line-height: 1.2;
  }

  .content_box {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .box_1 {
      //width: 45%;
      background-color: #f3f5f7;
      border-radius: 20px;
      padding: 30px 50px;
      height: 100%;

      #box_1_header {
        font-size: 16px;
        font-weight: 700;
        color: black;
      }

      ul {
        li {
          list-style-type: disc;
          line-height: 1.2;
          color: gray;
          margin-bottom: 14px;
          margin-left: 16px;
        }
      }
    }

    .box_2 {
      //width: 45%;
      background-color: #371450;
      border-radius: 20px;
      padding: 30px 50px;
      height: 100%;

      #box_2_header {
        font-size: 16px;
        font-weight: 700;
        color: white;
      }

      ul {
        li {
          list-style-type: disc;
          line-height: 1.2;
          color: white;
          margin-bottom: 14px;
          margin-left: 16px;
        }
      }
    }
  }
}

.content_two_section {
  .payment_counter_box {
    margin-bottom: 30px;
    #title {
      color: black;
      font-size: 16px;
      font-weight: 700;
    }

    #sub_title {
      color: gray;
      line-height: 1.2;
    }
  }

  .operating_hours_boxs {
    margin-bottom: 30px;
    #title {
      color: black;
      font-size: 16px;
      font-weight: 700;
    }

    #sub_title {
      color: gray;
      line-height: 1.2;
      margin-bottom: 0;
    }

    #sub_title2 {
      color: gray;
      line-height: 1.2;
      margin-top: 15px;
    }
  }

  .health_insurance_box {
    margin-bottom: 30px;
    #title {
      color: black;
      font-size: 16px;
      font-weight: 700;
    }

    #sub_title {
      color: gray;
      line-height: 1.2;
    }
  }
}

.content_three_section {
  .box_1 {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    .colour_1 {
      width: 45%;
      height: fit-content;
      background-image: linear-gradient(to right, #fef8f2, #f2e8e2);
      padding: 20px;
      border-radius: 20px;
      transition: height 0.3s ease-in-out;

      .title {
        color: black;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0;
        cursor: pointer;
      }

      .subtitle {
        margin-top: 20px;
        color: gray;
        line-height: 1.2;
        opacity: 0;
        overflow: hidden;
      }
    }

    .colour_1.active .subtitle {
      opacity: 1;
    }

    .colour_2 {
      width: 45%;
      height: fit-content;
      background-image: linear-gradient(to right, #eff4ff, #cad6f0);
      padding: 20px;
      border-radius: 20px;
      transition: height 0.3s ease-in-out;

      .title {
        color: black;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0;
        cursor: pointer;
      }

      .subtitle {
        margin-top: 20px;
        color: gray;
        line-height: 1.2;
        opacity: 0;
        overflow: hidden;
      }
    }

    .colour_2.active .subtitle {
      opacity: 1;
    }
  }

  .medical_record_section {
    margin-top: 50px;

    .title {
      color: black;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 0;
    }

    .subtitle {
      color: gray;
    }
    .accordian {
      width: 100%;
      margin: 0 auto;
    }

    .accordion-item {
      margin-bottom: 5px;
      background-color: transparent;
      color: #967F65;
      border: 0px;
      border-bottom: 1px solid #967F65;

      .accordion-header {
        display: flex;
        width: 100%;
        padding: 10px;
        text-align: left;
        font-size: 16px;
        cursor: pointer;
      }

      .accordion-content {
        background-color: transparent;
        color: gray;
        padding: 10px;
        margin-left: 20px;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.content_four_section {
  .grid-container-room {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    // height: 70%;
    .grid-item {
      background-color: #f3f5f7;
      border-radius: 30px;
      padding: 10px;
      cursor: pointer;
      height: 100%;

      img {
        border-radius: 25px;
        width: 100%;
        aspect-ratio: 1/1 ;
      }

      #room_name {
        margin-top: 10px;
        padding: 10px;
        font-size: 18px;
        color: black;
        font-weight: 700;
        line-height: 1.3;
        margin-bottom: 0;
      }

      #room_price {
        padding-left: 10px;
        font-size: 26px;
        color: #967F65;
        font-weight: 700;
      }
    }
  }
}

.content_five_section {
  .visiting_hours_box {
    #title {
      font-size: 16px;
      color: black;
      font-weight: 700;
    }

    #subtitle {
      color: gray;
      line-height: 1.2;
      margin-bottom: 20px;
    }

    .box_1 {
      //display: flex;
      margin-top: 30px;
      row-gap: 10px;

      .content_box_1 {
        //width: 45%;
        border: 1px solid #80808040;
        border-radius: 15px;
        padding: 15px;
        height: 100%;

        #title {
          font-size: 16px;
          color: #967F65;
          font-weight: 700;
          line-height: 1.2;
        }

        #subtitle {
          font-size: 16px;
          color: black;
          font-weight: 700;
          margin-bottom: 0;
        }
      }
    }

    .box_2 {
      //display: flex;
      //gap: 20px;
      margin-top: 20px;

      .box_2a {
        //width: 45%;
        height: fit-content;
        background-image: linear-gradient(to right, #fef8f2, #f2e8e2);
        padding: 20px;
        border-radius: 20px;
        transition: height 0.3s ease-in-out;

        .title {
          color: black;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 0;
          cursor: pointer;
        }

        .subtitle {
          margin-top: 20px;
          color: gray;
          line-height: 1.2;
          opacity: 0;
          overflow: hidden;
        }
      }

      .box_2a.active .subtitle {
        opacity: 1;
      }

      .box_2b {
        //width: 45%;
        height: fit-content;
        //background-image: linear-gradient(to right, #eff4ff, #cad6f0);
        background-image: linear-gradient(108.17deg, #FBF9FF 5.1%, #CBE7F0 104.56%);
        padding: 20px;
        border-radius: 20px;
        transition: height 0.3s ease-in-out;

        .title {
          color: black;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 0;
          cursor: pointer;
        }

        .subtitle {
          margin-top: 20px;
          color: gray;
          line-height: 1.2;
          opacity: 0;
          overflow: hidden;
        }
      }

      .box_2b.active .subtitle {
        opacity: 1;
      }
    }
  }

  .other_services_box {
    margin-top: 50px;

    .title {
      color: black;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 0;
    }

    .subtitle {
      color: gray;
    }
    .accordian {
      width: 100%;
      margin: 0 auto;
    }

    .accordion-item {
      margin-bottom: 5px;
      background-color: transparent;
      color: #967F65;
      border: 0px;
      border-bottom: 1px solid #967F65;

      .accordion-header {
        display: flex;
        width: 100%;
        padding: 10px;
        text-align: left;
        font-size: 16px;
        cursor: pointer;
      }

      .accordion-content {
        background-color: transparent;
        color: gray;
        padding: 10px;
        margin-left: 20px;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.content_six_section {
  .title {
    color: black;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
  }

  .subtitle {
    color: gray;
  }

  .grid-container-room {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    .grid-item {
      background-color: #f3f5f7;
      border-radius: 30px;
      padding: 10px 10px 20px 10px;

      img {
        border-radius: 25px;
      }

      #hotel_name {
        margin-top: 10px;
        padding: 10px;
        font-size: 18px;
        color: black;
        font-weight: 700;
        line-height: 1.3;
        margin-bottom: 0;
      }

      #hotel_address {
        padding: 0px 10px;
        font-size: 16px;
        color: gray;
        line-height: 1.3;
        margin-bottom: 0;
      }

      #hotel_tel {
        margin-top: 10px;
        padding: 10px;
        font-size: 16px;
        color: black;
        line-height: 1.3;
        margin-bottom: 0;
      }

      #hotel_web {
        padding: 0px 10px 10px 10px;
        font-size: 16px;
        color: black;
        line-height: 1.3;
        margin-bottom: 0;
      }
    }
  }

  .public_transportation_box {
    margin-top: 50px;
    #title {
      font-size: 18px;
      color: black;
      font-weight: 700;
    }

    #subtitle {
      color: gray;
      line-height: 1.2;
    }

    .bus_service {
      #title {
        font-size: 18px;
        color: black;
        font-weight: 700;
      }

      ul {
        margin-left: 20px;

        #public_type {
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        #public_route {
          font-size: 14px;
          font-weight: normal;
          margin-bottom: 0;
        }

        li {
          list-style-type: disc;
          color: black;
        }
      }
    }

    .lrt_service {
      margin-top: 20px;
      #title {
        font-size: 18px;
        color: black;
        font-weight: 700;
      }

      #subtitle {
        color: black;
        margin-bottom: 0;
      }

      ul {
        margin-left: 20px;
        li {
          list-style-type: disc;
          color: black;
        }
      }

      #fare_costs {
        margin-bottom: 0;
        color: black;
      }
    }
  }
}

.content_seven_section {
  .title {
    font-size: 14px;
    font-weight: normal;
    color: black;
    margin-bottom: 10px;
  }

  .next {
    background-color: #ccd5ed;
    width: 200px;
    padding: 10px 30px;
    color: black;
    border: 0;
    border-radius: 30px;
    margin-top: 50px;
    font-weight: 600;
  }

  .provios {
    background-color: #eaedf8;
    width: 200px;
    padding: 10px 30px;
    color: black;
    border: 0;
    border-radius: 30px;
    margin-top: 50px;
    font-weight: 600;
  }

  .gl_form {
    //.title {
    //  font-size: 18px;
    //  font-weight: normal;
    //  color: black;
    //  margin-bottom: 10px;
    //}

    label {
      color: black;
      font-size: 12px;
    }
    .css-tj5bde-Svg,
    .css-8mmkcg {
      background: transparent;
      color: black;
    }

    .css-1u9des2-indicatorSeparator {
      background-color: transparent;
    }
    #form_firstName,
    #form_nric,
    #form_country {
      display: flex;
      flex-direction: column;
      width: 400px;
      height: 90px;
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
      justify-content: center;
    }

    #form_gender {
      display: flex;
      flex-direction: column;
      width: 400px;
      height: 90px;
      background-color: #eaedf8;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
      justify-content: center;
    }

    #form_ins_com,
    #form_gl_rec {
      display: flex;
      flex-direction: column;
      width: 400px;
      height: 70px;
      background-color: #eaedf8;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
      justify-content: center;
    }

    #form_dob {
      display: flex;
      flex-direction: column;
      width: 400px;
      height: 90px;
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
      justify-content: center;
      .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 0;
        font-size: 12px;
      }

      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }

    #form_phoneNum {
      display: flex;
      flex-direction: column;
      width: 400px;
      height: 90px;
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
      justify-content: center;
    }
  }

  .content_gl_tab2 {
    .form_ {
      display: flex;
      flex-direction: column;
      width: 400px;
      height: 90px;
      background-color: #eaedf8;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
      justify-content: center;
    }
    #form_doc {
      display: flex;
      flex-direction: column;
      width: 400px;
      height: 90px;
      background-color: #eaedf8;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
      justify-content: center;
    }
    #form_dob {
      display: flex;
      flex-direction: column;
      width: 400px;
      height: 90px;
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
      justify-content: center;
      .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 0;
        font-size: 12px;
      }

      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  }
  .insu {
    padding-top: 3%;
  }

  .upload_ic {
    padding-top: 3%;
  }
  .patient_safety {
    // p {
    //   color: black;
    //   margin-bottom: 0;
    // }

    // #title {
    //   font-size: 18px;
    //   font-weight: 700;
    //   color: black;
    //   margin-bottom: 10px;
    // }

    // ul {
    //   margin-left: 20px;
    //   margin-bottom: 10px;
    //   li {
    //     list-style-type: disc;
    //     color: black;
    //   }
    // }
  }

  .safeguard_your_hospital {
    margin-top: 20px;
    p {
      color: black;
      line-height: 1.2;
    }

    #title {
      font-size: 18px;
      font-weight: 700;
      color: black;
      margin-bottom: 10px;
    }
  }

  .safety_goals {
    margin-top: 40px;
    p {
      color: black;
      line-height: 1.2;
    }

    #title {
      font-size: 18px;
      font-weight: 700;
      color: black;
      margin-bottom: 10px;
    }
  }

  .concerns_complaints {
    margin-top: 40px;
    p {
      color: black;
      line-height: 1.2;
    }

    #title {
      font-size: 18px;
      font-weight: 700;
      color: black;
      margin-bottom: 10px;
    }

    .contact_info {
      p {
        margin-bottom: 0;
      }
    }
  }
}

.content_eight_section {
  #title {
    font-size: 18px;
    font-weight: 700;
    color: black;
    margin-bottom: 10px;
  }

  ul {
    margin-left: 20px;
    margin-bottom: 10px;
    li {
      list-style-type: disc;
      color: black;
    }
  }
}

.individual_room_section {
  #back_to_btn {
    border: 0;
    background-color: transparent;
    color: #967F65;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .image_container {
    //width: 35%;

    #main_image {
      border-radius: 20px;
      margin: 5px;
      width: 100%;
    }

    .sub_image {
      margin: 5px;
      display: grid;
      grid-template-columns: auto auto auto auto;
      grid-gap: 10px;

      .overlap {
        position: relative;
        cursor: pointer;
        /* background: antiquewhite; */

        span {
          z-index: 999;
          color: white;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      img {
        border-radius: 15px;
        height: 100%;
        //margin: 0px 4px;
        //width: 69px;
        //height: 69px;

        &:last-child {
          cursor: pointer;
          filter: brightness(30%);
          //  filter: grayscale(80%);
        }
      }
    }

    .btn_container {
      margin-top: 15px;
      margin-left: 15px;

      #share_btn {
        border: 0;
        background-color: transparent;
        font-size: 16px;
        margin-right: 50px;
      }

      #download_btn {
        border: 0;
        background-color: transparent;
        font-size: 16px;
      }
    }
  }

  .room_details_container {
    //width: 60%;
    padding-left: 30px;
    #room_name {
      font-size: 22px;
      font-weight: 700;
      color: black;
    }

    #room_price {
      font-size: 22px;
      font-weight: 700;
      color: #967F65;
    }

    .grid-container-room {
      .grid-item {
        border: 1px solid #8080804a;
        border-radius: 10px;
        background-color: transparent;
        padding: 10px;
        height: 120px;
        cursor: default;
        display: flex;
        // justify-content: center;
        align-items: center;

        #room_type {
          margin-bottom: 0;
          color: black;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.2;
        }

        #room_type_price {
          margin-bottom: 0;
          color: gray;
          font-size: 16px;
          line-height: 1.2;
        }
      }
    }

    .place_offers_container {
      //  margin-top: 68%;

      .place_offers {
        height: 150px;
      }
      padding-top: 20px;

      #title {
        font-size: 16px;
        font-weight: 700;
        color: black;
        margin-bottom: 10px;
      }

      .grid_container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .grid_item {
          font-size: 14px;

          img {
            width: 30px;
          }

          p {
            font-size: 12px;
          }

          span {
            margin-right: 5px;
            font-size: 16px;
          }
        }
      }

      #room_rate_ps {
        margin-top: 30px;
        font-size: 15px;
        color: gray;
        font-weight: 700;
        line-height: 1.2;
      }
    }
  }
}

.image_gallery_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  .container {
    background-color: #fff;
    padding: 15px !important;
    border-radius: 20px;
    width: fit-content;
    position: relative;
    .close {
      position: absolute;
      left: 95%;
      top: 1%;
    }

    .big_image {
      img {
        width: 500px;
        height: 500px;
        border-radius: 15px;
      }
    }

    .small_image {
      // width: 300px;
      padding: 50px 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        font-size: 18px;
        font-weight: 700;
        color: black;
        line-height: 1.2;
      }
    }

    .grid_container {
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;

      img {
        width: 70px;
        height: 70px;
        border-radius: 10px;
        border: 2px solid transparent;
        cursor: pointer;

        &.selected {
          border-color: #967F65;
        }
      }
    }
  }
}

.mob_view {
  display: none;
}
.web_view {
  display: block;
}

@media (max-width: 768px) {
  .sidebar_group {
    .sidebar {
      .container {
        padding-left: 20px;
      }
    }
  }
}

@media (max-width: 480px) {
  .mob_view {
    display: block;
    position: relative;
  }
  .web_view {
    display: none;
  }
  .sidebar_group {
    display: flex;
    flex-direction: column;
    .sidebar {
      flex: 1;
      width: 100%;
      background-color: white;

      a.mob_maintitle {
        font-weight: 700;
        position: absolute;
        background: white;
        width: 75%;
        /* top: 50%; */
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 10px 25px;
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: space-between;
      }

      ul.dropdown_admission {
        padding: 40px;
        background: #80808012;

        li {
          background: white;
          margin: 10px;
          padding: 10px 20px;
          border-radius: 5px;
          &.active {
            color: #967F65;
          }
        }
      }

      .container {
        padding: 0;
        padding-left: 0;

        ul {
          width: 100%;
          li {
            font-size: 18px;
            color: black;
            cursor: pointer;

            &.active {
              font-size: 22px;
              font-weight: 700;
              color: #967F65;
              position: relative;
              padding-left: 30px;

              span {
                position: absolute;
                left: 0;
                font-weight: 900;
              }
            }
          }
        }
      }
    }

    .sidebar_content {
      flex: 2;
      width: 100%;
      float: right;
      min-height: 100vh;

      .container {
        padding: 10px;
        margin-top: 30px;
        // padding-bottom: 0;
      }
    }

    .mobile-sidebar-modal {
      display: flex;
      align-items: center;
      justify-content: center;
      height: inherit;
      padding: 10px;
      .container {
        background: white;
        border-radius: 10px;
      }

      li {
        padding: 10px;
        border-bottom: 1px solid #00000036;
        &.active {
          color: #967F65;
        }
      }
    }
  }

  .content_four_section {
    .grid-container-room {
      grid-template-columns: repeat(1, 1fr);

      .grid-item {
        #room_price {
          font-size: 20px;
        }
      }
    }

    .individual_room_section {
      .mob_view {
        display: flex;
      }
      .grid-container-room {
        gap: 10px;
        height: auto;
        grid-template-columns: repeat(3, 1fr);
      }

      .room_details_container {
        .grid-container-room {
          .grid-item {
            height: 80px;

            #room_type {
              font-size: 10px;
            }

            #room_type_price {
              font-size: 9px;
              text-align: center;
            }
          }
        }

        .place_offers_container {
          img {
            width: 30px;
          }
          p {
            font-size: 9px;
          }

          #room_rate_ps {
            font-size: 11px;
          }
        }
      }

      .image_gallery_modal {
        .container {
          .close {
            left: 90%;
          }
        }
      }
    }
  }

  .content_five_section {
    .visiting_hours_box {
      .box_1 {
        margin-top: 0;
        margin-bottom: 10px;

        .content_box_1 {
          // margin-top: 30px;
          height: fit-content;
        }
      }

      .box_2 {
        margin: 0;

        .box_2a {
          margin-top: 20px;
        }

        .box_2b {
          margin-top: 10px;
        }

      }
    }

    .other_services_box {
      margin-top: 0px;
    }
  }

  .outpatient_clinic_section {
    .content_box {
      .box_1 {
        padding: 15px;
        margin-bottom: 10px;
        height: fit-content;
      }

      .box_2 {
        padding: 15px;
        height: fit-content;
      }
    }
  }

  .content_seven_section {
    .gl_form {
      #form_ins_com {
        width: 100%;
      }

      #form_gl_rec {
        width: 100%;
      }
    }

    .content_gl_tab2 {
      .form_ {
        width: 100%;
      }
    }
  }
}
