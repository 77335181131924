.header-menu-area {
  color: white;
  height: 65px;
  display: flex;
  align-items: center;
  background: var(--primary-color);
  position: relative;
  z-index: 1;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .home-header-logo {
      width: 70%;
      height: 100%;
    }
  }

  .header-login {
    span {
      margin-left: 5px;
    }
  }
  .LoginRegisterPopupContainer {
    .MuiButton-contained {
      box-shadow: none;
    }
    .MuiButton-containedPrimary {
      color: #fff;
      background-color: transparent;
    }
  }
}

/* 2. Header */
/* header-top */
:root {
  --primary-color: #967F65;
  --font-size-large: 24px;
  --active-button: #fbf3de;
}

header {
  background: var(--primary-color);
}

//.logo img {
//  width: 244px;
//  height: 60px;
//}
.primary-color {
  color: #967F65 !important;
}

// .header-lang img {
//   width: 20px !important;
//   height: auto !important;
//   border: 2px solid #fff !important;
// }

header {
  background: var(--primary-color);
}

.header-lang span {
  color: white !important;
}

.header-lang i.fas.fa-angle-down:before {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 1px 3px 1px 5px;
  background: #6783d5;
}

.header-lang i.fas {
  font-weight: 100 !important;
}

.header-lang-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  color: white;
  border-radius: 5px;
}

.header-lang-list li {
  padding: 5px 20px;
}

.header-lang-list li a {
  text-decoration: none;
  color: #333;
}

/* Add caret to the top of the ul */
.header-lang-list::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 25%;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent white transparent;
}

.fas.fa-lock {
  color: white;
  border: 1px solid;
  padding: 6px;
  border-radius: 50%;
}

i.fas.fa-bars {
  color: white;
  padding: 6px;
  border-radius: 50%;
  font-size: 20px;
}

.main-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.fa-bars:before {
  content: "\f0c9";
}
.header_tag a {
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 13px;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  color: #fff;

  height: 60px;
}

.top-bar {
  background: #f3f6ff;
}

.header-info {
  padding-left: 20px;
}

.header-info span {
  margin-right: 39px;
}

.header-info span i {
  margin-right: 10px;
}

.header-padding {
  padding: 0 120px;
  padding-top: 45px;
}

.transparrent-header {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;
}

.header-cta-info {
  margin-right: 45px;
  margin-top: 8px;
}

.header-cta-icon {
  margin-right: 20px;
}

.header-cta-text h5 {
  margin-bottom: 10px;
}

.header-profile {
  img {
    width: 45px;
    height: auto;
    border-radius: 50%;
  }
  &:hover {
    .header-profile-list {
      opacity: 1;
      visibility: visible;
      box-shadow: 0px 4px 10px 0px #B7BBCA5C;

    }
  }
  .fa-angle-down {
    &:before {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      padding: 1px 3px 1px 5px;
      background: #6783d5;
    }
  }
  svg {
    color: #626262;
  }
  .header-profile-list {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    color: white;
    border-radius: 5px;
    position: absolute;
    width: 150px;
    left: -26%;
    opacity: 0;
    visibility: hidden;
    border-top: 3px solid;
    transition: 0.4s;
    z-index: 999;
    background: #fff;
    padding-bottom: 5px;
    padding-top: 4px;
    top: 152%;
    li {
      display: block;
      margin-bottom: 0;
      padding: 5px 10px;
    }
    a {
      color: #626262;
      &:hover {
        color: #626262;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: 25%;
      transform: translateX(-50%);
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent white transparent;
    }
  }
}

.header-cart {
  position: relative;

  .view-cart-container {
    position: relative;
    margin: 5% 1%;
    display: flex;
    justify-content: flex-end;

    .view-cart-button {
      margin: 5px 10px;
      background: var(--primary-color);
      color: white !important;
      border-radius: 20px;
      padding: 10px 30px;
      right: 0;
      font-size: 10px;
    }
  }

  &:hover {
    .header-cart-list {
      opacity: 1;
      visibility: visible;
      box-shadow: 0px 4px 10px 0px #B7BBCA5C;
    }
  }
  .fa-angle-down {
    &:before {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      padding: 1px 3px 1px 5px;
      background: #6783d5;
    }
  }
  svg {
    color: white;
  }
  span {
    position: absolute;
    border: 1px solid var(--fourth-color);
    background-color: var(--fourth-color);
    border-radius: 50%;
    font-size: 12px;
    padding-right: 1px;
    width: 17px;
    height: 17px;
    top: -10px;
    right: -7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-cart-list {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    border-radius: 5px;
    position: absolute;
    width: 400px;
    left: -360px;
    opacity: 0;
    visibility: hidden;
    border-top: 3px solid;
    transition: 0.4s;
    z-index: 999;
    background: #fff;
    padding-bottom: 5px;
    padding-top: 4px;
    top: 152%;
    li {
      display: block;
      margin-bottom: 0;
      padding: 5px 10px;
      border-bottom: 3px solid #f3f3f3;
    }

    .no-border {
      border-bottom: 3px solid transparent;
    }
    a {
      color: #626262;
      &:hover {
        color: #626262;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: 93%;
      transform: translateX(-50%);
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent white transparent;
    }
    .cart-detail {
      font-weight: 700;
      // padding: 20px 0;
    }
    .cart-footer {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      h4.cart-price {
        color: var(--fourth-color);
      }
    }
  }
}

/* header menu area */
.sticky_menu {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  z-index: 9999999;
  background: var(--primary-color);
  // animation: 800ms ease-in-out 0s normal none 1 running fadeInDown;
  // box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
  // -webkit-transition: all 0.3s ease-out 0s;
  // -moz-transition: all 0.3s ease-out 0s;
  // -ms-transition: all 0.3s ease-out 0s;
  // -o-transition: all 0.3s ease-out 0s;
  // transition: all 0.3s ease-out 0s;
}

.sticky_menu.sticky_navBar_bg {
  background: #223645;
}

.sticky_menu.header-padding {
  padding-top: 0px;
}

.h3_navBar {
  position: relative;
  z-index: 9999;
}

.logo {
  img {
    position: relative;
    z-index: 2;
    width: 80%;
  }
}

.logo-circle::before {
  position: absolute;
  content: "";
  border: 47px solid #fff;
  left: -35px;
  width: 150px;
  height: 150px;
  top: -35px;
  border-radius: 100%;
  z-index: 1;
}

.header__menu ul li {
  display: inline-block;
  margin-left: 30px;
  position: relative;
}

.header__menu.menu-dark ul li:first-child {
  margin-left: 0;
}

.header__menu ul li a {
  color: #647589;
  font-weight: 500;
  padding: 48px 0;
  display: block;
  font-size: 14px;
}

.header__menu.header-menu-white ul li a {
  color: #ffff;
}

.header__menu.header-menu-white ul li a:hover {
  color: #e12454;
}

.header__menu.menu-dark ul li a {
  padding: 20px 0;
  color: #b2bfcf;
}

.header__menu ul li a:hover {
  color: #223645;
}

.header__menu.menu-dark ul li a:hover {
  color: #fff;
}

.header__menu ul li ul.submenu {
  position: absolute;
  background: #ffffff;
  width: 250px;
  top: 110%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  padding: 25px 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-top: 5px solid #e12454;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
  z-index: 99;
}

.header__menu.header-menu-white ul li ul.submenu {
  background: #001d26;
}

.header__menu.menu-dark ul li ul.submenu {
  background: #223645;
}

.header__menu.menu-dark ul li ul.submenu li a {
  color: #b2bfcf;
}

.header__menu.menu-dark ul li ul.submenu li a:hover {
  color: #fff;
}

.header__menu ul li ul.submenu li {
  margin: 0;
  display: block;
}

.header__menu ul li ul.submenu li a {
  padding: 11px 30px;
}

.header__menu.header-menu-white ul li ul.submenu li:hover > a {
  color: #e12454;
}

.header__menu ul li:hover ul.submenu {
  opacity: 1;
  visibility: visible;
  top: 96%;
}

.header__menu ul li ul.submenu li:hover > a {
  color: #223645;
}

.header-social-icons {
  padding-top: 48px;
  padding-right: 25px;
}

.main-menu ul li:hover > a {
  color: #223645;
}

.header-right {
  margin-left: 12px;
}

.header-left {
  margin-right: 12px;
}

.header-menu-area {
  .css-1dimb5e-singleValue {
    color: white !important;
  }
}

.css-tj5bde-Svg, .css-8mmkcg {
  background: #eaedf8;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  color: black; 
}

.css-4hyi7q-control {
  box-shadow: none !important;
}
.css-4hyi7q-control:hover {
  border-color: transparent !important;
}
.css-lomv0o-control:hover {
  border-color: transparent !important;
}

.css-lomv0o-control {
  box-shadow: none !important;
}

.react-select__option:last-child {
  border-bottom: none !important;
}
.header-social-icons ul li {
  display: inline-block;
}

.header-menu-search {
  margin-left: 24px;
}

.header-social-icons ul li a {
  font-weight: 500;
  color: #647589;
  display: inline-block;
  margin: 0 8px;
}

.header-menu-blue .header-social-icons ul li a:hover {
  color: #fff;
}

.header-social-icons ul li a:hover {
  color: #223645;
}

.header-lang a {
  position: relative;
}

.header-lang span {
  color: #0a1121;
  font-weight: 500;
  letter-spacing: 1px;
}

.header-lang span {
  color: #0a1121;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 13px;
}

.header-lang-list {
  position: absolute;
  width: 100px;
  left: -11%;
  opacity: 0;
  visibility: hidden;
  border-top: 3px solid;
  transition: 0.4s;
  z-index: 999;
  background: #fff;
  padding-bottom: 5px;
  padding-top: 4px;
  top: 152%;
}

img.lang {
  margin-right: 10px;
}

.header-lang-list li:first-child {
  border-bottom: 1px solid grey;
}

.header-lang-list-3 {
  margin-top: 15px;
}

.header-lang {
  // cursor: pointer;
  display: flex !important;
  align-items: center;
}

@media (max-width: 480px) {

  .header-menu-area .home-header-logo {
    width: 90% !important;
  }

  .header-lang{
    display: none !important;
  }

  .header-cart .header-cart-list {
    display: none !important;
  }
}

.header-lang img {
  width: 60px;
  height: 60px;
  // border: 3px solid #fff;
  // box-shadow: 0px 8px 16px 0px rgba(241, 87, 19, 0.2);
  // border-radius: 50%;
}

.header-lang a {
  display: inline-block;
}

.header-menu .nav-pills .nav-link.active,
.header-menu .nav-pills .show > .nav-link {
  color: var(--primary-color);
  background-color: var(--active-button);
  font-weight: 600;
}

.header-menu {
  .active-tab-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;

    .tab {
      height: 65px;
      cursor: pointer;
      padding: 10px;
      text-align: center;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
  }
  .tab.active {
    background-color: #ffffff;
    color: #967F65;
    font-weight: 600;
  }
}

.header-lang:hover .header-lang-list {
  opacity: 1;
  visibility: visible;
}

.header-lang-list li {
  display: block;
  margin-bottom: 0;
  padding: 5px 10px;
}

.header-lang-list li a {
  display: block;
  color: #647589;
  font-size: 13px;
}

.header-lang-list li a:hover {
  display: block;
  color: #e12454;
}

/* Menu Search -------------------------------------------------------*/
.nav-search {
  position: relative;
  display: block;
  color: inherit;
}

.nav-search:hover {
  color: inherit;
}

.search-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 121;
  background: rgba(0, 0, 0, 0.9);
}

.search-wrap .search-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.search-wrap .search-cell {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.search-wrap .search-field-holder {
  width: 50%;
  margin: auto;
  position: relative;
  animation: slideInUp 0.3s;
}

.search-wrap .main-search-input {
  width: 100%;
  height: 70px;
  border: 0;
  padding: 0 50px;
  text-transform: uppercase;
  background: transparent;
  font-size: 25px;
  color: #fff;
  border-bottom: 2px solid #898989;
  text-align: center;
  letter-spacing: 2px;
}

.search-wrap input.form-control,
.search-wrap input.form-control:focus {
  background-color: #fff;
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

input.main-search-input::-webkit-input-placeholder {
  color: #fff;
  font-size: 25px;
}

input.main-search-input:-moz-placeholder {
  color: #fff;
  opacity: 1;
  font-size: 25px;
}

input.main-search-input::-moz-placeholder {
  color: #fff;
  opacity: 1;
  font-size: 25px;
}

input.main-search-input:-ms-input-placeholder {
  color: #fff;
  font-size: 25px;
}

.search-close {
  position: absolute;
  top: 50px;
  right: 50px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

.mobile-links li > a {
  padding: 15px 0 15px 15px;
  line-height: 20px;
  border-bottom: 1px solid #ebebeb;
  color: #7f7f7f;
  display: block;
}

.mobile-links li > a:hover {
  color: #b79d82;
}

// sidebar start
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  z-index: 99999999;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: white;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

.offcanvas-header {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // padding: 30px 30px 10px;
  box-shadow: 0 4px 2px -2px #A7A7A7;
  position: relative;
}

.side__bar a {
  color: black;
}

.offcanvas-end {
  width: 70vw;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 0;
  overflow-y: auto;

  .sidebar_search_btn {
    width: -webkit-fill-available;
    border: none;
    background: transparent;
    text-align: left;
    font-size: 17px;
    color: #606060;
  }

  ul {
    li {
      font-size: 17px;
      padding: 10px 15px;
    }
  }
}

.side-info {
  width: auto;
  height: 100%;
  position: static;
  z-index: 9999;
  right: inherit;
  top: 0;
  padding: 0px;
  transition: 0.6s;
  overflow-y: inherit;
}

.sidebar_sub_menu {
  list-style: none;
}
